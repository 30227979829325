import { CIcon } from '~/icons/types'

export const ciPencil: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M17.123 2.837a2.857 2.857 0 1 1 4.04 4.04L20.03 8.01l-4.04-4.04 1.133-1.133ZM13.97 5.99 2 17.96V22h4.04l11.97-11.97-4.04-4.04Z',
      fill: 'currentColor'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'pencil',
  type: 'solid'
}
